#home {
}
.title {
    @apply max-w-5xl;
}
@media (prefers-color-scheme: dark) {
  .title {
    text-shadow: 0px 2px 8px #000000CC;
    /*text-shadow: 0px -2px 8px #000000,0px 2px 8px #000000;*/
  }
}
@media (prefers-color-scheme: light) {
  .title {
    text-shadow: 0px -2px 8px #FFFFFFFF;
    /*text-shadow: 0px -4px 0px #FFFFFFFF,0px 4px 0px #FFFFFFFF,-4px 0px 0px #FFFFFFFF,4px 0px 0px #FFFFFFFF*/
    /*-webkit-text-stroke: 1px #FFF;*/
    /*text-shadow: 10px -10px 0 #FFFFFF,*/
  }
}
.article {
    @apply flex flex-col place-items-center justify-center min-h-screen;
}
.circles {
  text-align:center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0!important;
  padding: 0!important;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  /*background: rgba(255, 255, 255, 0.2);*/
  /*background: var(--color-highlight);*/
  animation: animate 25s linear infinite;
  bottom: -150px;
  mask: url(/guy-dumais-icon.svg) no-repeat center;
  mask-size: cover;
  @apply bg-accent-1 dark:bg-accent-4;
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
  background: var(--main-brand);
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  background: var(--main-brand);
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  background: var(--light-accent);
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  background: var(--dark-accent);  
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
  background: var(--dark-accent);  
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
  background: var(--main-brand);
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0.25;
        border-radius: 75%;
    }
}